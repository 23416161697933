//price toggle
const checkboxToggler = document.querySelector('.price-toggle-checkbox');
const items = [...document.querySelectorAll('.price-tag')]; //nodelist to array
const priceTagDuration = [...document.querySelectorAll('.tag')];

let priceData = {};
const tenOffMarkup = (isYearly = true) => `
                    <p class='tag'> /Maad</p>
                    ${isYearly ? "<span class='badge badge--small'> 10% OFF</span>" : ''}
                `;

function updatePrice({ newPlans, increment = true }) {
  newPlans.forEach((plan, i) => {
    // Price Tag
    const priceCard = document.getElementById(`plan-${plan.name}`);

    // update the pricing btn
    const btnGetStarted = priceCard?.querySelector('.btn-getStarted');
    const { color, content } = btnGetStarted.dataset;
    btnGetStarted.innerHTML = increment
      ? `<i style='color: ${color};' class="bi bi-circle-fill"></i> ${content} <i style='color: ${color};' class="bi bi-circle-fill"></i>`
      : '💼 Bespaar nu met jaarlijks! 💼';

    priceCard.dataset.plan = plan._id;

    priceCard.dataset.isYearly = plan.yearly;
    const priceTag = priceCard?.querySelector('.price-tag');

    const planMonthlyPrice = priceData.monthly.filter((pl) => pl.name === plan.name)[0]
      .price;

    // Initial price and increment or decrement value
    let initialValue = increment ? planMonthlyPrice * 0.9 : planMonthlyPrice;
    const targetValue = increment ? planMonthlyPrice : planMonthlyPrice * 0.9;

    let incrementTimes = 5;

    // const finalPrice = initialValue * 0.9;
    const inDecVal = (initialValue * 0.1) / incrementTimes;

    for (let i = 1; i <= incrementTimes; i++) {
      setTimeout(() => {
        initialValue =
          i === incrementTimes
            ? targetValue
            : increment
            ? initialValue + inDecVal
            : initialValue - inDecVal;
        priceTag.innerHTML = `${initialValue.toFixed(2)}&euro;`;

        i === incrementTimes &&
          (priceTag.closest('.main').querySelector('.sub').innerHTML = tenOffMarkup(
            plan.yearly
          ));
      }, i * 100);
      // incrementTimes === i && setFinalClearInterval(plan, priceTag);
    }

    // Set Final Price Function
    function setFinalClearInterval(plan, priceTag) {
      priceTag.closest('.main').querySelector('.sub').innerHTML = tenOffMarkup(
        plan.yearly
      );
      priceTag.innerHTML = `${plan.price}&euro;`;
      clearInterval(intervalId);
    }
  });
}

function handleToggle(e) {
  const wasMonthly = checkboxToggler.dataset.monthly === 'true';

  if (wasMonthly) {
    checkboxToggler.dataset.monthly = 'false';
    return updatePrice({ newPlans: priceData.yearly, increment: false });
  }

  if (!wasMonthly) {
    checkboxToggler.dataset.monthly = true;
    return updatePrice({ newPlans: priceData.monthly });
  }
}

async function initToggle() {
  const {
    data: {
      data: { documnets },
    },
  } = await axios('/api/v1/plans');

  priceData = documnets.reduce(
    (acc, plan) => {
      if (plan.yearly === true) acc.yearly = [...acc.yearly, plan];
      else if (plan.price === 0) acc.free = [plan];
      else acc.monthly = [...acc.monthly, plan];

      return acc;
    },
    { yearly: [], monthly: [], free: [] }
  );

  checkboxToggler.removeAttribute('disabled');

  checkboxToggler?.addEventListener('click', handleToggle);
}

export default initToggle;
