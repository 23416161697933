import generateResult from './reusables/fetch.js';
import { showProgress } from './reusables/showProgressBtn.js';
import showError from './reusables/showError.js';

const stripe = Stripe(
  'pk_live_51LS2YWKjacNoRPwfjAPgDGuzmiXc1TxFw79PH8jmWhQzNSoVHPs2eO7oo5A3GenpW5X4jIGBrt39q76HN3QmXifG00yvmvLHWz'
);

const btnContinue = document.getElementById('btn-continue');
const modalPay = document.getElementById('modal-pay');

export default function handleGetStarted(e) {
  if (!e.target.closest('.btn-getStarted')) return;
  const planCard = e.target.closest('.card-pricing');
  const { planName, isYearly } = planCard.dataset;

  // Added started
  let plans = [];
  const { monthly, yearly } = e.target.closest('.section-pricing').dataset;

  if (isYearly === 'true') plans = JSON.parse(yearly);
  else plans = JSON.parse(monthly);

  const { paymentLink } = plans.filter((pl) => pl.name === planName)[0];
  return location.assign(paymentLink);

  const label = document.getElementById('paymodal-label');
  modalPay.dataset.planId = planCard.dataset.plan;
  btnContinue.addEventListener('click', subscribeToPlan);
  label.innerText = `Kies nu voor het ${
    isYearly == 'true' ? 'Jaarlijks' : 'Maandelijks'
  } ${planName.toUpperCase()} plan!`;
  btnContinue.innerHTML = btnContinue.innerHTML.replace(
    '{plan_name}',
    planName.toUpperCase()
  );
}
modalPay?.addEventListener('hide.bs.modal', (e) => {
  btnContinue.textContent = 'Activeer {plan_name} Plan';
  btnContinue.removeEventListener('click', subscribeToPlan);
});
async function subscribeToPlan(e) {
  try {
    const { planId } = e.target.closest('#modal-pay').dataset;
    showProgress(btnContinue);
    // get session from the server
    const { session } = await generateResult({
      url: `/api/v1/plans/checkout-session/${planId}`,
    });
    // Create a checkout form plus charge users
    await stripe.redirectToCheckout({ sessionId: session.id });
  } catch (err) {
    showError(err, btnContinue, 'Try Again!');
  }
}
