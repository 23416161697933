import { showAlert } from './alert.js';
import { removeProgress } from './showProgressBtn.js';

export default function (err, btn, btnContent) {
  const data = err.response?.data;

  showAlert(
    'danger',
    data ? data.message : 'Er is iets fout gegaan. Probeer het opnieuw!'
  );

  console.log(err);

  removeProgress(btn, btnContent);
}
